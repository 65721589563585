export function isKey(e, key) {
	if(!e.key) return false;
	key = key.toLowerCase();
	var k = e.key.toLowerCase();
	if (e.ctrlKey) k = "ctrl+" + k;
	if (e.altKey) k = "alt+" + k;
	if (e.shiftKey) k = "shift+" + k;
	if (k === key) {
		e.preventDefault();
		e.stopPropagation();
		if (e.srcElement.blur) e.srcElement.blur();
		return true;
	} else {
		return false;
	}
}